

const Loader = (props)=>{
    return(
        <div className="loader-bg">
           <div className="loader-track">
              <div className="loader-fill"></div>
           </div>
        </div>
    )
}
export default Loader;