


const FooterComponent = (props)=>{
    return (
        <footer className="pc-footer">
            <div className="footer-wrapper container-fluid">
                <div className="row">
                </div>
            </div>
        </footer>
    );
}

export default FooterComponent;