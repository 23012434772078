



const UpdateUser = (props)=>{
    return (
        <div>
            <h1>ddd</h1>
        </div>
    )
}

export default UpdateUser;